<template>
  <div>
    <!-- <div class="section">
      <div style="position: relative">
        <v-img
          :eager="true"
          max-height="350"
          cover
          style="border-radius: 10px"
          src="https://images.pexels.com/photos/220455/pexels-photo-220455.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        ></v-img>
        <v-card
          max-width="300"
          class="py-8 px-10"
          style="top: -50px; left: -60px; position: absolute"
        >
          <h3 class="mb-3"><b>Lorem ipsum</b></h3>
          <p style="font-size: 14pt">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel
            egestas orci, sit amet tempor dolor.
          </p>
        </v-card>

        <v-card
          max-width="300"
          class="py-8 px-10"
          style="bottom: -50px; right: -60px; position: absolute"
        >
          <h3 class="mb-3"><b>Lorem ipsum</b></h3>
          <p style="font-size: 14pt">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel
            egestas orci, sit amet tempor dolor.
          </p>
        </v-card>
      </div>
    </div> -->

    <div class="section text-center" id="team">
      <!-- <p class="text-primary"><b>Lorem ipsum</b></p> -->
      <h2 class="mb-10" style="font-size: 22pt">Team</h2>
      <div class="team">
        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/gerard.jpeg')"
          ></v-img>
          <h3 class="mt-4 mb-0">Gerard van Kaathoven</h3>
          <p class="mt-3">
            CEO bij diverse internationale financiële instellingen in Nederland
            en buitenland
          </p>
        </v-card>

        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/antoinette.png')"
          ></v-img>
          <h3 class="mt-4 mb-0">Antoinette Janssen</h3>
          <p class="mt-3">
            Kunstenaar in <br />
            's-Hertogenbosch
          </p>
        </v-card>

        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/jos.jpeg')"
          ></v-img>
          <h3 class="mt-4 mb-0">Jos van Haaren</h3>
          <p class="mt-3">Management consultant in Nederland en buitenland</p>
        </v-card>

        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/jan.jpeg')"
          ></v-img>
          <h3 class="mt-4 mb-0">Jan Aussems</h3>
          <p class="mt-3">
            Extensieve ervaring als huisarts in ‘s-Hertogenbosch
          </p>
        </v-card>
      </div>
    </div>

    <v-img src="@/assets/bar-secondary.svg"></v-img>

    <SponsorSection />
  </div>
</template>

<script>
import SponsorSection from "@/components/SponsorSection.vue";
import { useMeta } from "vue-meta";

export default {
  components: {
    SponsorSection,
  },
  setup() {
    useMeta({
      title: "Organisatie",
    });
  },
};
</script>

<style lang="scss" scoped>
.team {
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 700px) {
  .team {
    grid-template-columns: 1fr;
  }
}
</style>
